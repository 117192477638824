<template>
  <div>
    <p>{{ placeholder }}</p>
    <textarea
      ref="editor"
      :value="value"
      :placeholder="placeholder"
    ></textarea>
  </div>
</template>

<script>

// import editor from '@ckeditor/ckeditor5-build-classic'
// import editor from 'ckeditor5-custom-build/build/ckeditor'
import editor from '@/../ckeditor5/build/ckeditor'
import { ref, watch } from '@vue/composition-api'
import UploadAdapter from './UploadAdapter'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default() {
        return {
          uploadUrl: 'product', // api upload post path
          postUuid: '', // post uuid to attach this upload
        }
      },
    },
  },

  mounted() {
    const uploader = editor => (editor.plugins.get('FileRepository').createUploadAdapter = loader => new UploadAdapter(loader, this.options))

    const editorConfig = {
      extraPlugins: [uploader],
      fontColor: {
        colors: [
          {
            color: 'hsl(171, 83%, 37%)',
            label: 'Primary Green',
          },
          {
            color: 'hsl(175, 81%, 32%)',
            label: 'Second Green',
          },
          {
            color: 'hsl(36, 99%, 58%)',
            label: 'title-orange',
          },
          {
            color: 'hsl(201, 80%, 19%)',
            label: 'dark-blue',
          },
          {
            color: 'hsl(215, 10%, 47%)',
            label: 'light-gray',
          },
          {
            color: 'hsl(0, 0%, 94%)',
            label: 'dark-gray',
          },
          {
            color: 'hsl(0, 100%, 50%)',
            label: 'error-red',
          },

          // ...
        ],
      },
    }

    const oldValue = ref(this.value)

    editor.create(this.$refs.editor, editorConfig).then(instance => {
      instance.model.document.on('change:data', () => {
        oldValue.value = instance.getData()

        this.$emit('input', oldValue.value)
      })

      watch(() => this.value, () => {
        if (oldValue.value === this.value) return

        instance.setData(this.value)
      })
    })
  },
}
</script>
