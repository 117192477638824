import appStore from '@/modules/app/store'
import dayjs from 'dayjs'
import { isToday } from './index'

export const kFormatter = (num, fraction = 2) => (num > 999 ? `${(num / 1000).toFixed(fraction)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })

  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')

  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

function pad(num, size) {
  let n = num.toString()
  while (n.length < size) n = `0${n}`

  return n
}

export const hourToString = hour =>
  // const hours = pad(parseInt(hour, 10))
  // const minute = pad((hour - parseInt) * 60, 2)

  // return `${hours.toString()}:${minute.toString()}`
  now()
    .second(0)
    .hour(hour)
    .minute(Math.round((hour - parseInt(hour)) * 60))
    .format('HH:mm')

export const now = dayjs
export const dateSimple = string => dayjs(string).format('MM-DD HH:mm')
export const dateTime = string => dayjs(string).format('MM-DD HH:mm:ss')
export const date = string => dayjs(string).format('YYYY-MM-DD HH:mm:ss')
export const diff = (string, unit) => dayjs(string).diff(dayjs(), unit)

/**
 * Format price to base currency (e.g. hkd)
 * @param {number} value price to format
 * @returns {string} formatted string
 */
export const price = value => {
  const { currencies } = appStore.state.info

  const baseCurrency = currencies.find(c => c.base)

  return `${baseCurrency?.symbol}${parseFloat(value).toFixed(baseCurrency?.decimal)}`
}

/**
 * Formats price with currency and decimal according to specific currencies (from-to)
 * @param {*} value amount to format
 * @param {*} options options object
 * @param {string} [options.currency] currency code to use for both from and to currency
 * @param {string} [options.fromCode] base currency
 * @param {string} [options.toCode] currency to show
 * @param {number} [options.decimal] decimal places to round to
 * @returns {string} formatted string
 */
export const localPrice = (value, options = {}) => {
  let numValue = value
  const { currencies } = appStore.state.info
  const fromCode = options.fromCode || options.currency
  const toCode = options.toCode || options.currency

  const fromCurrency = fromCode ? currencies.find(c => c.code === fromCode) : currencies.find(c => c.base)
  const toCurrency = toCode ? currencies.find(c => c.code === toCode) : currencies.find(c => c.base)

  if (fromCurrency?.code !== toCurrency?.code) {
    numValue = (numValue * toCurrency.rate) / fromCurrency.rate
  }

  const places = options.decimal === undefined ? toCurrency?.decimal : options.decimal

  return `${toCurrency?.symbol}${parseFloat(numValue).toFixed(places)}`
}

/** Alias */
export const money = value => price(value)

/**
 *
 * @param {order|checkout} instance can be order or checkout
 * @param {checkout} checkout, use instance if null
 * @returns string
 */
export const displayStatusText = (instance, checkout = null) => {
  if (instance.refund_total > 0) {
    if (instance.refund_total === instance.total) return 'fully refunded'
    if (instance.refund_total) return 'partially refunded'
  } else if (instance.refund_credits_total > 0) {
    if (instance.refund_credits_total === instance.credits_total) return 'fully refunded'
    if (instance.refund_credits_total) return 'partially refunded'
  }

  return (checkout || instance).status === 'payment_succeeded' ? 'paid' : 'unpaid'
}

/**
 *
 * @param {order|checkout} instance can be order or checkout
 * @param {checkout} checkout, use instance if null
 * @returns string
 */
export const statusColor = (instance, checkout = null) => {
  if (instance.refund_total > 0) {
    if (instance.refund_total === instance.total) return 'warning'
    if (instance.refund_total) return 'error'
  } else if (instance.refund_credits_total > 0) {
    if (instance.refund_credits_total === instance.credits_total) return 'warning'
    if (instance.refund_credits_total) return 'error'
  }

  return (checkout || instance).status === 'payment_succeeded' ? 'success' : ''
}
