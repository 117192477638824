import axios from '@axios'

const timeout = 120 * 1000 // 120s

// --- Package ---

const storePackage = data => axios.post('/admin/product-packages', data)

const updatePackage = (packageId, data) => axios.put(`/admin/product-packages/${packageId}`, data)

const destroyPackage = packageId => axios.delete(`/admin/product-packages/${packageId}`)

const destroyPackageMapping = mappingId => axios.delete(`/admin/product-package-mappings/${mappingId}`)

const storePackageMapping = data => axios.post('/admin/product-package-mappings', data)

const updatePackageMapping = (id, data) => axios.put(`/admin/product-package-mappings/${id}`, data)

// --- SKU ---

const fetchSkus = params => axios.get('/admin/skus', { params })

const storeSku = data => axios.post('/admin/skus', data)

const updateSku = (skuId, data) => axios.put(`/admin/skus/${skuId}`, data)

const destroySku = skuId => axios.delete(`/admin/skus/${skuId}`)

const handleSkuStatus = (id, action = 'publish') => axios.post(`/admin/skus/${id}/${action}`)

const storeSkuBoilerplate = skuId => axios.post(`/admin/skus/${skuId}/boilerplate`)

const exportSkus = (params = {}) =>
  axios
    .get('/admin/skus/export.xlsx', {
      responseType: 'blob',
      timeout,
      params,
    })
    .then(({ data }) => {
      const xlsxBlobUrl = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
      window.open(xlsxBlobUrl)
    })

const exportVouchers = (params = {}) =>
  axios
    .get('/admin/vouchers/export.xlsx', {
      responseType: 'blob',
      timeout,
      params,
    })
    .then(({ data }) => {
      const xlsxBlobUrl = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
      window.open(xlsxBlobUrl)
    })

// --- Slot Rules ---

const fetchSkuSlotRules = params => axios.get('/admin/slot-rules', { params })

const storeSlotRule = data => axios.post('/admin/slot-rules', data)

const updateSlotRule = (id, data) => axios.put(`/admin/slot-rules/${id}`, data)

const destroySlotRule = id => axios.delete(`/admin/slot-rules/${id}`)

// --- Slots ---

const fetchSkuSlots = params => axios.get('/admin/sku-time-slots', { params })

const storeSlot = data => axios.post('/admin/sku-time-slots', data)

const storeSlotsFromRule = data => axios.post('/admin/sku-time-slots/from-rule', data, { timeout })

const updateSlot = (id, data) => axios.put(`/admin/sku-time-slots/${id}`, data)

const bulkUpdateSlots = data => axios.put('/admin/sku-time-slots/bulk', data)

const destroySlot = id => axios.delete(`/admin/sku-time-slots/${id}`)

const bulkDestroySlots = params => axios.delete('/admin/sku-time-slots/bulk', { params })

// --- Voucher Groups ---

const fetchVoucherGroups = params => axios.get('/admin/voucher-groups', { params })

const storeVoucherGroup = data => axios.post('/admin/voucher-groups', data)

const updateVoucherGroup = (id, data) => axios.put(`/admin/voucher-groups/${id}`, data)

const destroyVoucherGroup = id => axios.delete(`/admin/voucher-groups/${id}`)

// --- Voucher Template ---

const fetchVoucherTemplates = params => axios.get('/admin/voucher-templates', { params })

const storeVoucherTemplate = data => axios.post('/admin/voucher-templates', data)

const updateVoucherTemplate = (id, data) => axios.put(`/admin/voucher-templates/${id}`, data)

// --- Product Template ---

const fetchProductTemplates = params => axios.get('/admin/product-templates', { params })

const storeProductTemplate = data => axios.post('/admin/product-templates', data)

const updateProductTemplate = (id, data) => axios.put(`/admin/product-templates/${id}`, data)

// --- Activity Rule Template ---

const fetchActivityRuleTemplates = params => axios.get('/admin/activity-rule-templates', { params })

const storeActivityRuleTemplate = data => axios.post('/admin/activity-rule-templates', data)

const updateActivityRuleTemplate = (id, data) => axios.put(`/admin/activity-rule-templates/${id}`, data)

// Participant Rule

const fetchParticipantRules = params => axios.get('/admin/participant-rules', { params })

const storeParticipantRule = data => axios.post('/admin/participant-rules', data)

const updateParticipantRule = (id, data) => axios.put(`/admin/participant-rules/${id}`, data)

// Quota Rule

const fetchQuotaRules = params => axios.get('/admin/quota-rules', { params })

const storeQuotaRule = data => axios.post('/admin/quota-rules', data)

const updateQuotaRule = (id, data) => axios.put(`/admin/quota-rules/${id}`, data)

// --- Plan ---

const fetchPlans = params => axios.get('/admin/plans', { params })

const updatePlan = (id, data) => axios.put(`/admin/plans/${id}`, data)

// --- Slots ---

const fetchVouchers = params => axios.get('/admin/vouchers', { params })

const storeVouchersFromGroup = data => axios.post('/admin/vouchers/from-group', data)

const updateVoucher = (id, data) => axios.put(`/admin/vouchers/${id}`, data)

const destroyVoucher = id => axios.delete(`/admin/vouchers/${id}`)

const resendVoucher = (id, data) => axios.post(`/admin/vouchers/${id}/resend`, data)

// --- Photo ---

const storePhoto = data => axios.post('/admin/product-media/photos', data)

const updatePhoto = (id, data) => axios.put(`/admin/product-media/${id}`, data)

const destroyPhoto = id => axios.delete(`/admin/product-media/${id}`)

// --- Product ---

const storeProduct = data => axios.post('/admin/products', data)

const updateProduct = (productId, data) => axios.put(`/admin/products/${productId}`, data)

const destroyProduct = productId => axios.delete(`/admin/products/${productId}`)

const fetchProducts = params => axios.get('/admin/products', { params })

const fetchProduct = id => axios.get(`/admin/products/${id}`)

const handleProductStatus = (id, action = 'publish') => axios.post(`/admin/products/${id}/${action}`)

const replicateProduct = productId => axios.post(`/admin/products/${productId}/replicate`)

// --- Price ---

const fetchSkuPriceGroups = params => axios.get('/admin/price-groups', { params })

const storePriceGroup = data => axios.post('/admin/price-groups', data)

const updatePriceGroup = (id, data) => axios.put(`/admin/price-groups/${id}`, data)

const fetchSkuPrices = params => axios.get('/admin/prices', { params })

const storePrice = data => axios.post('/admin/prices', data)

const updatePrice = (priceId, data) => axios.put(`/admin/prices/${priceId}`, data)

const setItemCost = priceId => axios.post(`/admin/prices/${priceId}/set-item-cost`)

// --- channel price ---
// (no update, just store will handle update)
const storeChannelPrice = data => axios.post('/admin/channel-prices', data)

// --- attribute type ---

const fetchAllAttributeTypes = params => axios.get('/admin/attribute-types', { params })

const storeAttributeType = data => axios.post('/admin/attribute-types', data)

const updateAttributeType = (id, data) => axios.put(`/admin/attribute-types/${id}`, data)

const destroyAttributeType = id => axios.delete(`/admin/attribute-types/${id}`)

// --- attribute value ---

const fetchAllAttributeValues = params => axios.get('/admin/attribute-values', { params })

const storeAttributeValue = data => axios.post('/admin/attribute-values', data)

const updateAttributeValue = (id, data) => axios.put(`/admin/attribute-values/${id}`, data)

const destroyAttributeValue = id => axios.delete(`/admin/attribute-values/${id}`)

// --- Bulk Upload ---

const spreadSheetUpload = formData => axios.post('/admin/products/spreadsheet-upload', formData)

// --- Brand ---

const fetchBrands = params => axios.get('/admin/brands', { params })

const storeBrand = data => axios.post('/admin/brands', data)

const updateBrand = (id, data) => axios.put(`/admin/brands/${id}`, data)

const destroyBrand = id => axios.delete(`/admin/brands/${id}`)

const storeBrandMedia = data => axios.post('/admin/brand-media/photos', data)

// participant rules

const fetchAllParticipantRules = queryParams => axios.get('/admin/participant-rules', { params: queryParams })

export {
  bulkDestroySlots,
  bulkUpdateSlots,
  destroyAttributeType,
  destroyAttributeValue,
  destroyBrand,
  destroyPackage,
  destroyPackageMapping,
  destroyPhoto,
  destroyProduct,
  destroySku,
  destroySlot,
  destroySlotRule,
  destroyVoucher,
  destroyVoucherGroup,
  exportSkus,
  exportVouchers,
  fetchActivityRuleTemplates,
  fetchAllAttributeTypes,
  fetchAllAttributeValues,
  fetchAllParticipantRules,
  fetchBrands,
  fetchParticipantRules,
  fetchPlans,
  fetchProduct,
  fetchProducts,
  fetchProductTemplates,
  fetchQuotaRules,
  fetchSkuPriceGroups,
  fetchSkuPrices,
  fetchSkus,
  fetchSkuSlotRules,
  fetchSkuSlots,
  fetchVoucherGroups,
  fetchVouchers,
  fetchVoucherTemplates,
  handleProductStatus,
  handleSkuStatus,
  replicateProduct,
  resendVoucher,
  setItemCost,
  spreadSheetUpload,
  storeActivityRuleTemplate,
  storeAttributeType,
  storeAttributeValue,
  storeBrand,
  storeBrandMedia,
  storeChannelPrice,
  storePackage,
  storePackageMapping,
  storeParticipantRule,
  storePhoto,
  storePrice,
  storePriceGroup,
  storeProduct,
  storeProductTemplate,
  storeQuotaRule,
  storeSku,
  storeSkuBoilerplate,
  storeSlot,
  storeSlotRule,
  storeSlotsFromRule,
  storeVoucherGroup,
  storeVouchersFromGroup,
  storeVoucherTemplate,
  updateActivityRuleTemplate,
  updateAttributeType,
  updateAttributeValue,
  updateBrand,
  updatePackage,
  updatePackageMapping,
  updateParticipantRule,
  updatePhoto,
  updatePlan,
  updatePrice,
  updatePriceGroup,
  updateProduct,
  updateProductTemplate,
  updateQuotaRule,
  updateSku,
  updateSlot,
  updateSlotRule,
  updateVoucher,
  updateVoucherGroup,
  updateVoucherTemplate,
}
