<template>
  <v-autocomplete
    v-model="brandId"
    placeholder="Select Brand"
    :items="brandOptions"
    item-text="label"
    item-value="value"
    outlined
    hide-details
    :dense="dense"
    :clearable="clearable"
    :disabled="disabled"
  >
  </v-autocomplete>
</template>
<script>
import { useBrand } from '@/composables';
import { ref, watch } from '@vue/composition-api';

export default {
  model: {
    prop: 'filters',
    event: 'changed',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { brandOptions, fetchBrands } = useBrand()

    fetchBrands({ take: 999 })

    const brandId = ref(props.filters.brand_id)

    watch(brandId, () => emit('changed', {
      ...props.filters,
      brand_id: brandId.value,
    }))

    return {
      brandOptions,
      brandId,
    }
  },
}
</script>
