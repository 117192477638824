import appStore from '@/modules/app/store'

const fillLocales = (text = '') => {
  const results = {}
  const { locales } = appStore.state.info

  if (locales) {
    locales.forEach(locale => {
      results[locale] = text
    })
  }

  return results
}

export default function useTranslatable(field, name) {
  if (field && name && field[name]) {
    return field[name]
  }

  return fillLocales()
}
