<template>
  <div>
    <v-text-field
      v-for="locale in renderableLocales"
      v-show="formLocale === 'all' || formLocale === locale"
      :key="locale"
      :label="`${label} (${locale})`"
      :placeholder="`${placeholder}`"
      :hint="hint"
      outlined
      :value="value[locale]"
      :rules="[required]"
      @input="val => updateValue(val, locale)"
    ></v-text-field>
  </div>
</template>

<script>
import appStore from '@/modules/app/store'
import { required } from '@core/utils/validation'
import { computed, ref } from '@vue/composition-api'

export default {
  props: {
    locales: {
      type: Array,
      default() {
        return []
      },
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const curValue = ref(JSON.parse(JSON.stringify(props.value)))

    const formLocale = computed(() => appStore.state.formLocale)

    const mayChangeAllcurValue = value => {
      const defaultValue = curValue.value?.en

      Object.keys(curValue.value).forEach(locale => {
        if (curValue.value[locale] === defaultValue) {
          curValue.value[locale] = value
        }
      })
    }

    const updateValue = (value, locale) => {
      if (locale === 'en') {
        mayChangeAllcurValue(value)
      } else {
        curValue.value[locale] = value
      }

      emit('input', curValue.value)
    }

    const { locales } = props
    const renderableLocales = computed(() => (locales.length ? locales : appStore.state.info.locales))

    return {
      renderableLocales,
      formLocale,
      updateValue,
      required,
    }
  },
}
</script>
