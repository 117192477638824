import { bulkDestroySlots, bulkUpdateSlots, destroySlot, fetchSkuSlots } from '@/api/product'
import { useNotifyErrors } from '@/composables'
import { computed, ref } from '@vue/composition-api'
import dayjs from 'dayjs'

export default function useSlotForm() {
  const loading = ref(false)
  const selectedRows = ref([]) // of a sku
  const slots = ref([]) // of a sku

  const dates = ref([dayjs().format('YYYY-MM-DD'), dayjs().add(1, 'week').format('YYYY-MM-DD')])

  const orderedDates = computed(() => (dayjs(dates.value[1]).isSameOrAfter(dayjs(dates.value[0])) ? dates.value : dates.value.reverse()))

  const options = ref({
    sortBy: ['started_at'],
    sortDesc: [false],
  })

  const matchSlots = computed(() =>
    slots.value.filter(slot =>
      dayjs(slot.started_at).isBetween(dayjs(orderedDates.value[0]), dayjs(orderedDates.value[1] || orderedDates.value[0]), 'days', '[]'),
    ),
  )

  const functionEvents = d => (slots.value.find(s => dayjs(s.started_at).format('YYYY-MM-DD') === dayjs(d).format('YYYY-MM-DD')) ? ['#000'] : [])

  const loadSkuIdSlots = (skuId, queryParams) =>
    fetchSkuSlots({ sku_id: skuId, ...queryParams })
      .then(res => {
        const { records, pagination } = res.data.data
        slots.value = records

        // remove loading state

        return res
      })
      .catch(error => {
        console.log(error)

        return Promise.reject(error)
      })
      .finally(() => {
        loading.value = false
      })

  /**
   * With Tangible, we store slot group first,
   * then store slot inside the group.
   * group name is irrelevant.
   */

  const deleteSlot = slotId => {
    loading.value = true

    return destroySlot(slotId)
      .catch(err => {
        console.log(err)

        return Promise.reject(err)
      })
      .finally(() => {
        loading.value = false
      })
  }

  const bulkUpdate = async data => {
    loading.value = true
    await bulkUpdateSlots({ ids: selectedRows.value.map(s => s.id), ...data }).catch(useNotifyErrors)
    loading.value = false
    selectedRows.value = []
  }

  const bulkDelete = async () => {
    loading.value = true
    await bulkDestroySlots({ ids: selectedRows.value.map(s => s.id) }).catch(useNotifyErrors)
    loading.value = false
    selectedRows.value = []
  }

  return {
    loading,
    slots,
    selectedRows,

    dates,
    orderedDates,
    options,
    matchSlots,

    functionEvents,
    loadSkuIdSlots,
    deleteSlot,

    bulkUpdate,
    bulkDelete,
  }
}
