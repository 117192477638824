import { brandService } from '@/modules/product/services'
import { t } from '@/plugins/i18n'
import { computed, ref } from '@vue/composition-api'

export default () => {
  const brands = ref([])

  const brandOptions = computed(() =>
    brands.value.map(b => ({
      label: `${t(b.name)} (${b.slug})`,
      value: b.id,
    })),
  )

  return {
    brands,
    brandOptions,
    fetchBrands: query =>
      brandService.index(query).then(({ records }) => {
        brands.value = records

        return brands.value
      }),
  }
}
