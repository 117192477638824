<template>
  <v-row>
    <v-col col="6">
      <v-date-picker
        v-model="temp.date"
        color="primary"
        @input="updateTimestamp"
      ></v-date-picker>
    </v-col>
    <v-col col="6">
      <v-time-picker
        v-model="temp.time"
        color="primary"
        @input="updateTimestamp"
      ></v-time-picker>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import dayjs from 'dayjs'

export default {
  props: {
    // dayjs date time string
    value: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const temp = ref({
      date: dayjs(props.value).format('YYYY-MM-DD'),
      time: dayjs(props.value).format('hh:mm'),
    })

    const updateTimestamp = () => emit('input', dayjs(temp.value.date).startOf('day').set('hour', temp.value.time.split(':')[0]).set('minute', temp.value.time.split(':')[1])
      .toString())

    return {
      temp,

      updateTimestamp,
    }
  },
}
</script>
